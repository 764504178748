




























import { Employee } from "@/models/entities/employee.interface";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CEmplyeeCard extends Vue {
  @Prop({ required: false, default: null, type: Object })
  private employee: Employee;
  @Prop({ required: false, default: false, type: Boolean })
  private isSelected: boolean;
}
